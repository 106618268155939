import { FeaturesClient } from '@vp/ft-reader'
import { useEffect, useState } from 'react'

interface FeatureFlagResult {
  featureToggle: boolean
  loading: boolean
}

export const useFeatureFlag = (featureFlag: string): FeatureFlagResult => {
  const [loading, setLoading] = useState<boolean>(true)
  const [featureToggle, setFeatureToggle] = useState<boolean>(false)

  useEffect(() => {
    if (!featureFlag) {
      setLoading(false)
      return
    }

    const fetchData = async () => {
      const featureTogglesClient = await FeaturesClient.getFeaturesClient('consumer ame-web')
      const isEnabled = await featureTogglesClient.isFeatureEnabled(featureFlag)
      setFeatureToggle(isEnabled)
      setLoading(false)
    }
    fetchData()
  }, [featureFlag])

  return { featureToggle, loading }
}
